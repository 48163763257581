import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_ACCOUNTS } from '@/lib/react-query/keys.ts'
import { PaginatedResponse } from '@/lib/global'

export const PAGE_SIZE = 100

export interface Account {
  id: string
  name: string
}

export interface Totals {
  current_balance: Record<string, number>
  predicated_balance: Record<string, number>
}

export interface AccountsOutput extends PaginatedResponse<Account> {
  totals: Totals
}

export const useGetAccounts = (
  page: number,
  per_page: number,
  month?: number,
  year?: number,
  staleTime: number = 1000 * 60 * 5,
) => {
  return useQuery({
    queryKey: [GET_ACCOUNTS, page, month, year, per_page],
    staleTime,
    queryFn: async () => {
      const { data } = await api.get<AccountsOutput>(`/accounts`, {
        params: {
          page,
          per_page,
          month: month || undefined,
          year: year || undefined,
        },
      })
      return data
    },
  })
}
