import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_CATEGORIES } from '@/lib/react-query/keys.ts'
import { PaginatedResponse } from '@/lib/global'

export const PAGE_SIZE = 50

interface Category {
  id: string
  name: string
  color: string
  hide: boolean
}

export interface CategoriesOutput extends PaginatedResponse<Category> {}

type Options = Record<string, string>

export const useGetCategories = (
  page: number,
  perPage: number,
  params: Options = {},
  staleTime = 1000 * 60 * 5,
) => {
  return useQuery({
    queryKey: [GET_CATEGORIES, page, perPage, params],
    staleTime,
    queryFn: async () => {
      const { data } = await api.get<CategoriesOutput>(`/categories`, {
        params: {
          ...params,
          page,
          per_page: perPage,
        },
      })
      return data
    },
  })
}
