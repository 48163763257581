export const EnvironmentBanner = () => {
  if (import.meta.env.VITE_APP_ENV === 'production') {
    return null
  }

  return (
    <div className={'z-50 fixed w-full bg-yellow-200 text-center'}>
      <span className={'text-sm'}>
        Você está em modo de{' '}
        {import.meta.env.VITE_APP_ENV === 'development' ? (
          <strong>desenvolvimento</strong>
        ) : (
          <strong>homologação</strong>
        )}
        . Essa mensagem não irá aparecer em produção.
      </span>
    </div>
  )
}
