import { useCallback } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  Pagination as ShadPagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { cn } from '@/lib/utils'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx'

import { PaginatedMeta } from '@/lib/global'

interface PaginationProps {
  setPage?: (newPage: number) => void
  setPageSize?: (pageSize: number) => void
  pageSizeOptions?: number[]
  paginationData: PaginatedMeta
}

const PAGE_SIZE_OPTIONS = [50, 100]

export function Pagination({
  setPage,
  setPageSize,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  paginationData,
}: PaginationProps) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const changePage = useCallback(
    (newValue: number) => {
      let nextPage = newValue

      if (nextPage <= 0) nextPage = 1
      if (nextPage > paginationData.total_pages)
        nextPage = paginationData.total_pages

      if (setPage) {
        setPage(nextPage)
        return
      }

      const params = new URLSearchParams(searchParams.toString())
      params.set('page', nextPage.toString())
      navigate(`${pathname}?${params.toString()}`)
    },
    [paginationData, setPage, searchParams, navigate, pathname],
  )

  return (
    <div className="my-4 flex items-center justify-between">
      {!!paginationData.limit && !!setPageSize && (
        <div className={'w-[240px]'} />
      )}

      {(paginationData.total_pages || 1) > 1 && (
        <ShadPagination className="flex-1">
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                className={cn('cursor-pointer', {
                  'cursor-not-allowed': !paginationData.prev,
                })}
                onClick={() => {
                  if (paginationData.prev) {
                    changePage(paginationData.prev)
                  }
                }}
              />
            </PaginationItem>

            {paginationData.series
              .filter((item) => !Number.isNaN(Number(item)))
              .map((page) => (
                <PaginationLink
                  key={page}
                  isActive={Number(page) === Number(paginationData.page)}
                  onClick={() => changePage(page || 0)}
                >
                  {page}
                </PaginationLink>
              ))}

            <PaginationItem>
              <PaginationNext
                className={cn('cursor-pointer', {
                  'cursor-not-allowed': !paginationData.next,
                })}
                onClick={() => {
                  if (paginationData.next) {
                    changePage(paginationData.next)
                  }
                }}
              />
            </PaginationItem>
          </PaginationContent>
        </ShadPagination>
      )}

      {!!setPageSize && (
        <div className={'flex items-center gap-2 w-[240px]'}>
          <span className={'text-sm'}>Registros por página</span>
          <Select
            onValueChange={(value) => setPageSize?.(Number(value))}
            value={String(paginationData.limit)}
          >
            <SelectTrigger className="w-[80px]">
              <SelectValue placeholder="" />
            </SelectTrigger>
            <SelectContent>
              {pageSizeOptions.map((item) => (
                <SelectItem key={item} value={item.toString()}>
                  {item}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
    </div>
  )
}
