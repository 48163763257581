import { useCallback } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx'
import { LoadingContainer } from '@/components/loading-container.tsx'
import { Pagination } from '@/components/pagination.tsx'
import { TransactionsOutput } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  CircleAlert,
  CircleCheckBig,
  CreditCard,
  File,
  Pencil,
  Receipt,
} from 'lucide-react'
import { Tooltip } from '@/components/tooltip.tsx'
import { format, parseISO } from 'date-fns'
import { formatMoney } from '@/lib/format-money.ts'
import { cn } from '@/lib/utils.ts'
import { buildAttachmentUrl } from '@/lib/build-attachment-url.ts'
import { DeleteRegisterDialog } from '@/pages/transactions/components/delete-register-dialog.tsx'
import { UpdateAttachmentsDialog } from '@/pages/transactions/components/update-attachments-modal.tsx'
import { DeleteBankTransferDialog } from '@/pages/transactions/components/delete-bank-transfer-dialog.tsx'
import { UpdateBankTransferModal } from '@/pages/transactions/components/update-bank-transfer-modal.tsx'
import { UpdateTransactionModal } from '@/pages/transactions/components/update-transaction-modal.tsx'

interface TransactionsTableProps {
  transactions?: TransactionsOutput
  isLoading?: boolean
  isCreditCard: boolean
}

export function TransactionsTable({
  transactions,
  isLoading,
  isCreditCard = false,
}: TransactionsTableProps) {
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const setPage = useCallback(
    (newPage: number) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set('page', newPage.toString())
      navigate(`${pathname}?${params.toString()}`)
    },
    [navigate, pathname, searchParams],
  )

  const setPageSize = useCallback(
    (pageSize: number) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set('per_page', pageSize.toString())
      params.set('page', '1')
      navigate(`${pathname}?${params.toString()}`)
    },
    [navigate, pathname, searchParams],
  )

  const renderTitle = useCallback(
    (transaction: TransactionsOutput['data'][0]) => {
      if (
        transaction.repeat_transaction_id &&
        transaction.total_installments > 1
      )
        return `${transaction.title} (${transaction.current_installment}/${transaction.total_installments})`

      return transaction.title
    },
    [],
  )

  if (!transactions?.data.length) {
    return (
      <div className="my-10 w-full">
        <span className="flex items-center justify-center text-xl">
          {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
        </span>
      </div>
    )
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Situação</TableHead>
            <TableHead>Data</TableHead>
            <TableHead>Nome</TableHead>
            <TableHead>Categoria</TableHead>
            <TableHead>Conta</TableHead>
            <TableHead>Valor</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {transactions?.data.map((transaction) => (
            <TableRow className={'h-12'} key={transaction.id}>
              <TableCell>
                <div className={'flex items-center gap-2'}>
                  {transaction.effected && (
                    <Tooltip title={'Pago'}>
                      <CircleCheckBig color={'green'} className={'h-5 w-5'} />
                    </Tooltip>
                  )}
                  {!transaction.effected && (
                    <Tooltip title={'Pendente'}>
                      <CircleAlert color={'red'} className={'h-5 w-5'} />
                    </Tooltip>
                  )}

                  {transaction.credit_card?.id && (
                    <Tooltip title={transaction.credit_card.name}>
                      <CreditCard color={'black'} className={'h-5 w-5'} />
                    </Tooltip>
                  )}

                  {transaction.invoice_url && (
                    <Tooltip title={'Fatura'}>
                      <button
                        onClick={() => {
                          if (!transaction.invoice_url) return
                          window.open(
                            buildAttachmentUrl(transaction.invoice_url),
                            '_blank',
                          )
                        }}
                      >
                        <File
                          color={'orange'}
                          className={'h-5 w-5 cursor-pointer'}
                        />
                      </button>
                    </Tooltip>
                  )}

                  {transaction.receipt_url && (
                    <Tooltip title={'Comprovante'}>
                      <button
                        onClick={() => {
                          if (!transaction.receipt_url) return
                          window.open(
                            buildAttachmentUrl(transaction.receipt_url),
                            '_blank',
                          )
                        }}
                      >
                        <Receipt
                          color={'blue'}
                          className={'h-5 w-5 cursor-pointer'}
                        />
                      </button>
                    </Tooltip>
                  )}
                </div>
              </TableCell>
              <TableCell>
                {format(
                  parseISO(
                    isCreditCard ? transaction.date : transaction.billing_date,
                  ),
                  'dd/MM/yyyy',
                )}
              </TableCell>
              <TableCell>{renderTitle(transaction)}</TableCell>
              <TableCell>{transaction.category.name}</TableCell>
              <TableCell>{transaction.account.name}</TableCell>
              <TableCell
                className={cn({
                  'text-green-600': transaction.kind === 'income',
                  'text-red-600': transaction.kind === 'outcome',
                })}
              >
                {formatMoney(transaction.value)}
              </TableCell>
              <TableCell>
                <div className={'flex gap-2 items-center justify-center'}>
                  {transaction.bank_transfer ? (
                    <UpdateBankTransferModal
                      transaction={transaction}
                      triggerElement={
                        <>
                          <button>
                            <Pencil size={16} />
                          </button>
                        </>
                      }
                    />
                  ) : (
                    <UpdateTransactionModal
                      triggerElement={
                        <>
                          <button>
                            <Pencil size={16} />
                          </button>
                        </>
                      }
                      transaction={transaction}
                    />
                  )}
                  <UpdateAttachmentsDialog transaction={transaction} />
                  {transaction.bank_transfer ? (
                    <DeleteBankTransferDialog
                      bank_transfer={{
                        ...transaction.bank_transfer,
                        value: transaction.value,
                      }}
                    />
                  ) : (
                    <DeleteRegisterDialog transaction={transaction} />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        paginationData={transactions.meta}
        setPageSize={setPageSize}
        setPage={setPage}
      />
    </>
  )
}
