import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_ME } from '@/lib/react-query/keys.ts'

interface ProfileOutput {
  id: string
  name: string
  email: string
  avatar_url: string
  annotation: string
}

const getProfile = () => {
  return api.get<ProfileOutput>('/profile')
}

export const useGetProfile = () => {
  return useQuery({
    queryKey: [GET_ME],
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: async () => {
      const { data } = await getProfile()
      return data
    },
  })
}
