import { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx'
import {
  PAGE_SIZE,
  useGetCategories,
} from '@/lib/react-query/requests/categories/get-categories.ts'
import { Pencil, Trash } from 'lucide-react'
import { Button } from '@/components/ui/button.tsx'
import { Tooltip } from '@/components/tooltip.tsx'
import { LoadingContainer } from '@/components/loading-container.tsx'
import { Pagination } from '@/components/pagination.tsx'
import {
  AlertDialog,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx'
import { DeleteRegisterDialog } from '@/pages/categories/components/delete-register-dialog.tsx'
import { Dialog, DialogTrigger } from '@/components/ui/dialog.tsx'
import { UpdateCategoryModal } from '@/pages/categories/components/update-category-modal.tsx'

export function CategoriesTable() {
  const [page, setPage] = useState(1)

  const { data, isLoading } = useGetCategories(page, PAGE_SIZE, {}, 0)

  if (!data?.data.length) {
    return (
      <div className="my-10 w-full">
        <span className="flex items-center justify-center text-xl">
          {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
        </span>
      </div>
    )
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Nome</TableHead>
            <TableHead>Cor</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.data.map((category) => (
            <TableRow key={category.id}>
              <TableCell>{category.name}</TableCell>
              <TableCell>
                <div
                  className={'h-8 w-8 rounded-full'}
                  style={{
                    backgroundColor: category.color,
                  }}
                ></div>
              </TableCell>
              <TableCell>
                {!category.hide && (
                  <>
                    <Tooltip title={'Editar'}>
                      <Dialog>
                        <DialogTrigger>
                          <Button size={'icon'} variant={'ghost'}>
                            <Pencil size={16} />
                          </Button>
                        </DialogTrigger>
                        <UpdateCategoryModal initialData={category} />
                      </Dialog>
                    </Tooltip>
                    <Tooltip title={'Remover'}>
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button size={'icon'} variant={'ghost'}>
                            <Trash color={'red'} size={16} />
                          </Button>
                        </AlertDialogTrigger>
                        <DeleteRegisterDialog category={category} />
                      </AlertDialog>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination paginationData={data.meta} setPage={setPage} />
    </>
  )
}
