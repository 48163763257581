import { EnvironmentBanner } from '@/components/environment-banner.tsx'
import { Outlet } from 'react-router-dom'

export const AppLayout = () => {
  return (
    <>
      <EnvironmentBanner />
      <Outlet />
    </>
  )
}
